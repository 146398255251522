@import '@/styles/mixin.scss';

@media (max-width: 370px) {
  .for_text_line {
    br {
      display: none;
    }
  }
}

.MinWidth {
  min-width: 320px;
}

.section1_mainlogo_pulse {
  position: relative;
  z-index: 1;

  > img {
    z-index: 1;
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 60px 0px #ff7710;
    border-radius: 50%;
    transform: scale(0.5);
    transform-origin: center center;
    animation: pulse-me 3s linear infinite;
    z-index: -1;
  }

  &::after {
    animation-delay: 1.5s;
  }

  @keyframes pulse-me {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      opacity: 0.2;
      transform: scale(1.657);
    }
    70% {
      opacity: 0.09;
    }
    100% {
      transform: scale(2.6);
      opacity: 0;
    }
  }
}

.section1_sublogo_float {
  &:nth-of-type(1) {
    opacity: 0.6;
    animation: 3s icon1 infinite linear;

    @keyframes icon1 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(20px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  &:nth-of-type(2) {
    opacity: 0.5;
    animation: 3s icon2 infinite linear;

    @keyframes icon2 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(5px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  &:nth-of-type(3) {
    opacity: 0.6;
  }

  &:nth-of-type(4) {
    opacity: 0.8;
    animation: 3s icon4 infinite linear;

    @keyframes icon4 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(10px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  &:nth-of-type(6) {
    opacity: 0.8;
    animation: 5s icon6 infinite linear;

    @keyframes icon6 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(20px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  &:nth-of-type(7) {
    opacity: 0.6;
  }

  &:nth-of-type(8) {
    opacity: 0.5;
    animation: 5s icon8 infinite linear;

    @keyframes icon8 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(5px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  &:nth-of-type(9) {
    opacity: 0.2;
    animation: 5s icon infinite linear;

    @keyframes icon {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(10px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
}

.section2_bg {
  background: radial-gradient(50% 50% at 50% 50%, #2a2a2a 43%, #1a1a1a 100%);
}

@media (min-width: 1024px) and (max-width: 1084px) {
  .section2_wrap {
    > div {
      padding: 32px 30px;

      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .review_slide {
    :global {
      .slick-slide {
        width: 360px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .review_slide {
    :global {
      .slick-slide {
        width: 340px;
      }
    }
  }
}

@media (max-width: 767px) {
  .review_slide {
    :global {
      .slick-slide {
        width: 320px;
      }
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}
