.section1_kv {
  padding-bottom: 28.13%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/kv_before_load_pc.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
  }
}
@media (max-width: 1023px) {
  .section1_kv {
    padding-bottom: 108.33%;

    &::before {
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/kv_before_load_m.webp');
    }
  }
}

.section3_bg {
  &::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 160px;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/section3_bg_pc.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain;
  }
}

@media (max-width: 1023px) {
  .section3_bg {
    &::before {
      height: 84.18px;
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/section3_bg_m.png');
    }
  }
}

.section3_slider {
  :global {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        width: 40px;
        height: 40px;
        background-color: rgba(23, 23, 23, 1);
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-cld-2nd/section4_slider_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        margin-top: 57px;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: -64px;
        transform: translate(0, -50%) rotate(180deg);
      }
      &.slick-next {
        right: -64px;
      }
    }

    .slick-dots {
      top: 0;
      bottom: initial;
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 146px;
      overflow: hidden;

      &::before,
      &::after {
        position: absolute;
        top: 0;
        content: '';
        width: 30%;
        height: 100%;
        z-index: 1;
      }
      &::before {
        left: 0;
        background: linear-gradient(270deg, rgba(38, 38, 38, 0) 0%, rgba(38, 38, 38, 1) 100%);
      }
      &::after {
        right: 0;
        background: linear-gradient(270deg, rgba(38, 38, 38, 1) 0%, rgba(38, 38, 38, 0) 100%);
      }

      li {
        position: relative;
        width: auto;
        height: auto;

        button {
          position: relative;
          height: 73px;
          padding: 0;
          z-index: 3;

          span {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            display: inline-block;
            width: 100%;
            height: 48px;
            color: #d4d4d4;
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
          }

          &::before {
            display: none;
          }

          &::after {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0);
            content: '';
            width: 12px;
            height: 12px;
            background-color: #262626;
            background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-cld-2nd/section4_dots_deco.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px;
            border-radius: 50%;
            z-index: 3;
          }
        }

        &.slick-active {
          button {
            span {
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
              background-image: none;
            }
          }

          &::before,
          &::after {
            position: absolute;
            bottom: 5.5px;
            content: '';
            width: 100vw;
            height: 2px;
            z-index: 0;
          }
          &::before {
            background-color: #ff7710;
          }
          &::after {
            background-color: #a3a3a3;
          }
        }

        &:nth-of-type(1) {
          button {
            width: 90px;
            span {
              width: 100%;
            }
          }
          &::before {
            left: calc(-100vw + 45px);
          }
          &::after {
            right: calc(-100vw + 45px);
          }
        }

        &:nth-of-type(2) {
          button {
            width: 120px;
          }
          &::before {
            left: calc(-100vw + 60px);
          }
          &::after {
            right: calc(-100vw + 60px);
          }
        }

        &:nth-of-type(3) {
          button {
            width: 50px;
          }

          &::before {
            left: calc(-100vw + 25px);
          }
          &::after {
            right: calc(-100vw + 25px);
            background-color: #ff7710;
          }
        }
      }

      &:has(li:nth-of-type(2).slick-active) {
        li:nth-of-type(1) {
          button {
            span {
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
              background-image: none;
            }
          }
        }
      }

      &:has(li:nth-of-type(3).slick-active) {
        li:nth-of-type(1),
        li:nth-of-type(2) {
          button {
            span {
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
              background-image: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .section3_slider {
    :global {
      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          top: 102px;
          width: 24px;
          height: 24px;
          background-color: #262626;
          background-size: 16px 16px;
          border-radius: 4px;
          margin-top: 0;
          z-index: 10;

          &::before {
            display: none;
          }

          &.slick-disabled {
            opacity: 0.5;
          }
        }
        &.slick-prev {
          left: 0;
          transform: rotate(180deg);
        }
        &.slick-next {
          right: 0;
          transform: none;
        }
      }

      .slick-dots {
        gap: 15px;

        &::before,
        &::after {
          width: 66px;
        }
        &::before {
          background: linear-gradient(270deg, rgba(23, 23, 23, 0) 0%, rgba(23, 23, 23, 1) 100%);
        }
        &::after {
          background: linear-gradient(270deg, rgba(23, 23, 23, 1) 0%, rgba(23, 23, 23, 0) 100%);
        }

        li {
          button {
            height: 73px;

            span {
              padding-top: 8px;
              height: 50px;
              font-size: 12px;
              font-family: 'Pretendard';
              background-color: #404040;
              border-radius: 4px;
            }

            &::before {
              position: absolute;
              top: 50px;
              left: 50%;
              transform: translate(-50%, 0);
              display: inline-block;
              width: 9px;
              height: 5px;
              font-size: 0;
              background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/section3_deco_arrow_m.png');
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              opacity: 1;
            }
          }

          &.slick-active {
            &::before,
            &::after {
              width: 100vw;
              height: 2px;
            }
          }

          &:nth-of-type(1) {
            button {
              width: 80px;
            }
            &::before {
              left: calc(-100vw + 35px);
            }
            &::after {
              right: calc(-100vw + 35px);
            }
          }

          &:nth-of-type(2) {
            button {
              width: 90px;
            }
            &::before {
              left: calc(-100vw + 49px);
            }
            &::after {
              right: calc(-100vw + 49px);
            }
          }

          &:nth-of-type(3) {
            button {
              width: 80px;
            }
            &::before {
              left: calc(-100vw + 35px);
            }
            &::after {
              right: calc(-100vw + 35px);
            }
          }
        }
      }
    }
  }
}

.curriculum_accordion_wrap {
  li {
    &:first-of-type {
      .curriculum_accordion_dot_box {
        align-items: flex-start;

        span {
          &::before {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      .curriculum_accordion_dot_box {
        span {
          &::after {
            display: none;
          }
        }
      }
    }

    .curriculum_accordion_dot_box {
      span {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          content: '';
          width: 2px;
          height: 999vh;
          background-color: #a3a3a3;
          z-index: 0;
        }

        &::before {
          top: calc(-999vh - 2px);
        }
        &::after {
          bottom: calc(-999vh - 2px);
        }
      }
    }
  }

  .curriculum_accordion_dot_ul {
    position: relative;

    &::before {
      position: absolute;
      top: 40px;
      left: 5.4px;
      content: '';
      width: 2px;
      height: calc(100% - 87px);
      background-color: #a3a3a3;
      z-index: 0;
    }
  }
}

.condition_inner_dot,
.condition_outer_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 17px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #262626;
    border-radius: 50%;
  }
}

.condition_outer_dot {
  padding-left: 18px;
  &::before {
    top: 9.5px;
    left: 6px;
  }
}

@media (max-width: 1023px) {
  .condition_inner_dot {
    padding-left: initial;
    &::before {
      display: none;
    }
  }
  .condition_outer_dot {
    padding-left: 18px;

    &::before {
      top: 8px;
    }
  }
}

.step_text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .step_text_dot {
    padding-left: 11px;
    &::before {
      top: 16px;
    }
  }
}

.section5_bg {
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/section5_bg_pc.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 1023px) {
  .section5_bg {
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/section5_bg_m.png');
  }
}

.section5_dot {
  position: relative;
  padding-left: 18px;

  &::before {
    position: absolute;
    top: 9px;
    left: 6px;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #737373;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .section5_dot {
    padding-left: 15px;

    &::before {
      top: 9px;
      left: 0;
    }
  }
}

.section6_slider {
  :global {
    .slick-list {
      padding-bottom: 60px;
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial;
        bottom: -100px;
        width: 40px;
        height: 40px;
        background-color: #171717;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section4_slider_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        z-index: 2;

        &:hover {
          background-color: #000;
        }

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }
  }
}

@media (max-width: 1023px) {
  .section6_slider {
    :global {
      .slick-list {
        position: relative;
        padding-bottom: 0;
      }

      .slick-dots {
        position: initial;
        margin-top: 0;

        li {
          width: 6px;
          height: 6px;
          margin: 0 4px;

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              top: 0;
              left: 0;
              opacity: 1;
              content: '';
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.5);
              border-radius: 50%;
            }
          }
          &.slick-active {
            button {
              &::before {
                background-color: #fff;
              }
            }
          }
        }
      }

      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          position: absolute;
          top: calc(50% - 32px);
          bottom: initial;
          width: 30px;
          height: 30px;
          background-color: #fff;
          background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/section6_slider_arrow_m.png');
          background-size: 16px 16px;
          border-radius: 50%;
          box-shadow:
            0px 10px 15px -3px rgba(0, 0, 0, 0.1),
            0px 4px 6px -2px rgba(0, 0, 0, 0.05);
          z-index: 2;

          &:hover {
            background-color: #fff;
          }
        }
        &.slick-prev {
          left: 18px;
          transform: translate(0, -50%) rotate(180deg);
        }
        &.slick-next {
          right: 18px;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

.teacher_desc_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #fff;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .teacher_desc_dot {
    padding-left: 11px;

    &::before {
      top: 15.5px;
    }
  }
}

.section7_bg {
  &::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 302px;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/section7_bg_pc.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain;
  }
}

@media (max-width: 1023px) {
  .section7_bg {
    &::before {
      height: 84.18px;
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/section7_bg_m.png');
    }
  }
}

.section9_bg {
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/section9_bg_pc.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

@media (max-width: 1023px) {
  .section9_bg {
    background-image: initial;
    &::before {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 84.18px;
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-13th/section9_bg_m.png');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: cover;
    }
  }
}

.section9_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .section9_dot {
    padding-left: 15px;

    &::before {
      top: 9px;
      left: 0;
    }
  }
}

.program_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .program_dot {
    padding-left: 11px;
    &::before {
      top: 8px;
    }
  }
}

.benefit_slider {
  :global {
    .slick-list {
      padding-left: 24.8%;
      padding-bottom: 84px;
    }

    .slick-slide {
      width: 419px;
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-color: #262626;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section4_slider_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        z-index: 2;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }
  }
}

@media (max-width: 1920px) {
  .benefit_slider {
    :global {
      .slick-list {
        padding-left: 16.7%;
      }
    }
  }
}

@media (max-width: 1728px) {
  .benefit_slider {
    :global {
      .slick-list {
        padding-left: 12.8%;
      }
    }
  }
}

@media (max-width: 1536px) {
  .benefit_slider {
    :global {
      .slick-list {
        padding-left: 8.4%;
      }
    }
  }
}

@media (max-width: 1512px) {
  .benefit_slider {
    :global {
      .slick-list {
        padding-left: 8%;
      }
    }
  }
}

@media (max-width: 1440px) {
  .benefit_slider {
    :global {
      .slick-list {
        padding-left: 5.5%;
      }
    }
  }
}

@media (max-width: 1299px) {
  .benefit_slider {
    :global {
      .slick-slide {
        width: 42vw;
      }
    }
  }
}

@media (max-width: 1023px) {
  .benefit_slider {
    :global {
      .slick-list {
        padding-left: initial;
        padding-bottom: 0;
      }

      .slick-slide {
        width: 312px;
      }

      .slick-dots {
        position: initial;
        margin-top: 30px;

        li {
          width: 6px;
          height: 6px;
          margin: 0 4px;

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              top: 0;
              left: 0;
              opacity: 1;
              content: '';
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.5);
              border-radius: 50%;
            }
          }
          &.slick-active {
            button {
              &::before {
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}

.faq_item_title {
  position: relative;
  padding-left: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Q.';
    margin-right: 4px;
    color: #ff7710;
  }
}

@media (max-width: 1023px) {
  .faq_item_title {
    padding-left: 18px;
  }
}
