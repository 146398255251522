.kv_video {
  padding-bottom: 28.125%;
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-4th/section1_kv_video_bg_pc.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;

    &::-webkit-media-controls {
      display: none !important;
    }
  }
}

@media (max-width: 1023px) {
  .kv_video {
    padding-bottom: 107.55%;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-4th/section1_kv_video_bg_m.png');
  }
}

.section3_bg {
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section3_bg_pc.png');
}

@media (max-width: 1023px) {
  .section3_bg {
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section3_bg_m.png');
  }
}

.section3_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 10px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .section3_dot {
    padding-left: 15px;

    &::before {
      top: 9px;
      left: 0;
    }
  }
}

.section4_slider {
  :global {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        width: 40px;
        height: 40px;
        background-color: rgba(23, 23, 23, 1);
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section4_slider_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        margin-top: 27px;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: -64px;
        transform: translate(0, -50%) rotate(180deg);
      }
      &.slick-next {
        right: -64px;
      }
    }

    .slick-dots {
      top: 0;
      bottom: initial;
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 146px;
      overflow: hidden;

      &::before,
      &::after {
        position: absolute;
        top: 0;
        content: '';
        width: 30%;
        height: 100%;
        z-index: 1;
      }
      &::before {
        left: 0;
        background: linear-gradient(270deg, rgba(38, 38, 38, 0) 0%, rgba(38, 38, 38, 1) 100%);
      }
      &::after {
        right: 0;
        background: linear-gradient(270deg, rgba(38, 38, 38, 1) 0%, rgba(38, 38, 38, 0) 100%);
      }

      li {
        position: relative;
        width: 20%;
        height: auto;

        button {
          position: relative;
          width: 100%;
          height: 38px;
          padding: 0;
          z-index: 3;

          &::before {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            opacity: 1;
            width: 100%;
            color: #a3a3a3;
            font-size: 12px;
            font-weight: 700;
            text-align: center;
            white-space: nowrap;
            z-index: 3;
          }

          &::after {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0);
            content: '';
            width: 12px;
            height: 12px;
            background-color: #262626;
            background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-cld-2nd/section4_dots_deco.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px;
            border-radius: 50%;
            z-index: 3;
          }
        }

        &.slick-active {
          button {
            &::before {
              opacity: 1;
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
              background-image: none;
            }
          }

          &::before,
          &::after {
            position: absolute;
            top: calc(50% + 11.5px);
            content: '';
            width: 100vw;
            height: 2px;
            z-index: 0;
          }
          &::before {
            left: calc(-100vw + 24px);
            background-color: #ff7710;
          }
          &::after {
            right: calc(-100vw + 24px);
            background-color: #a3a3a3;
          }
        }

        &:nth-of-type(1) {
          button {
            &::before {
              content: 'Stage 1';
            }
          }
        }

        &:nth-of-type(2) {
          button {
            &::before {
              content: 'Stage 2';
            }
          }
        }

        &:nth-of-type(3) {
          button {
            &::before {
              content: 'Stage 3';
            }
          }
        }

        &:nth-of-type(4) {
          button {
            &::before {
              content: 'Stage 4';
            }
          }
        }

        &:nth-of-type(5) {
          button {
            &::before {
              content: 'Final Stage';
            }
          }
          &::after {
            background-color: #ff7710;
          }
        }
      }

      &:has(li:nth-of-type(2).slick-active) {
        li:nth-of-type(1) {
          button {
            &::before {
              opacity: 1;
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
              background-image: none;
            }
          }
        }
      }

      &:has(li:nth-of-type(3).slick-active) {
        li:nth-of-type(1),
        li:nth-of-type(2) {
          button {
            &::before {
              opacity: 1;
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
              background-image: none;
            }
          }
        }
      }

      &:has(li:nth-of-type(4).slick-active) {
        li:nth-of-type(1),
        li:nth-of-type(2),
        li:nth-of-type(3) {
          button {
            &::before {
              opacity: 1;
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
              background-image: none;
            }
          }
        }
      }

      &:has(li:nth-of-type(5).slick-active) {
        li:nth-of-type(1),
        li:nth-of-type(2),
        li:nth-of-type(3),
        li:nth-of-type(4) {
          button {
            &::before {
              opacity: 1;
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
              background-image: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .section4_slider {
    :global {
      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          top: 62px;
          width: 24px;
          height: 24px;
          background-color: #262626;
          background-size: 16px 16px;
          border-radius: 4px;
          margin-top: 0;
          z-index: 10;

          &::before {
            display: none;
          }

          &.slick-disabled {
            opacity: 0.5;
          }
        }
        &.slick-prev {
          left: 0;
          transform: rotate(180deg);
        }
        &.slick-next {
          right: 0;
          transform: none;
        }
      }

      .slick-dots {
        gap: 0;
        &::before {
          background: linear-gradient(270deg, rgba(23, 23, 23, 0) 0%, rgba(23, 23, 23, 1) 100%);
        }
        &::after {
          background: linear-gradient(270deg, rgba(23, 23, 23, 1) 0%, rgba(23, 23, 23, 0) 100%);
        }

        li {
          button {
            &::before {
              font-family: 'Pretendard';
            }
          }
          &.slick-active {
            &::before {
              left: calc(-100vw + 50%);
            }
            &::after {
              right: calc(-100vw + 50%);
            }
          }
        }
      }
    }
  }
}

.section5_bg {
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-4th/section5_bg_pc.png');
}

@media (max-width: 1023px) {
  .section5_bg {
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section5_bg_m.png');
  }
}

.section5_pc_list {
  .video_box {
    padding-bottom: 56.29%;
  }
}

.section5_slider {
  :global {
    .slick-list {
      padding-bottom: 30px;
    }

    .slick-dots {
      position: initial;
      margin-top: 8px;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 100%;
          height: 100%;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 1;
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              background-color: #fff;
            }
          }
        }
      }
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        position: absolute;
        top: calc(50% - 45px);
        bottom: initial;
        width: 30px;
        height: 30px;
        background-color: #fff;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-4th/section5_slider_arrow_m.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px 16px;
        border-radius: 50%;
        z-index: 10;

        &::before {
          display: none;
        }
      }
      &.slick-prev {
        left: 9px;
        transform: translate(0, -50%) rotate(180deg);
      }
      &.slick-next {
        right: 9px;
        transform: translate(0, -50%);
      }
    }
  }

  .video_box {
    padding-bottom: 57.69%;

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;

      &::-webkit-media-controls {
        display: none !important;
      }
    }
  }
}

.section6_bg {
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section6_bg_pc.png');
}

@media (max-width: 1023px) {
  .section6_bg {
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section6_bg_re_m.png');
  }
}

.step_text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 10px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .step_text_dot {
    padding-left: 10px;

    &::before {
      top: 18px;
      width: 2px;
      height: 2px;
    }
  }
}

.section7_deco {
  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    width: 100px;
    height: 100px;
    background-color: #412906;
  }
}

@media (max-width: 1023px) {
  .section7_deco {
    &::before {
      bottom: -100px;
      width: 100%;
      height: 100px;
      z-index: 1;
    }
  }
}

@media (min-width: 1023px) and (max-width: 1279px) {
  .section7_custom {
    width: calc(100% - 644px);
  }
}

.section7_btn_bg {
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-4th/section7_btn_bg_pc.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 1023px) {
  .section7_btn_bg {
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-4th/section7_btn_bg_m.png');
  }
}

.section8_bg {
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section8_bg_pc.png');
}

@media (max-width: 1023px) {
  .section8_bg {
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section8_bg_m.png');
    background-repeat: repeat-x !important;
    background-size: contain;
  }
}

.teacher_text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #fff;
  }
}

.accordion_text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 8.5px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .accordion_text_dot {
    padding-left: 10px;

    &::before {
      top: 10px;
      width: 2px;
      height: 2px;
    }
  }
}

.project_accordion_wrap {
  li {
    &:first-of-type {
      .project_accordion_dot_box {
        align-items: flex-start;

        span {
          &::before {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      .project_accordion_dot_box {
        span {
          &::after {
            display: none;
          }
        }
      }
    }

    .project_accordion_dot_box {
      span {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          content: '';
          width: 2px;
          height: 999vh;
          background-color: rgba(255, 119, 16, 0.2);
          z-index: 0;
        }

        &::before {
          top: calc(-999vh - 2px);
        }
        &::after {
          bottom: calc(-999vh - 2px);
        }
      }
    }
  }

  .project_accordion_dot_ul {
    position: relative;

    &::before {
      position: absolute;
      top: 58px;
      left: 5.4px;
      content: '';
      width: 2px;
      height: calc(100% - 115px);
      background-color: #a3a3a3;
      z-index: 0;
    }
  }
}

@media (max-width: 1023px) {
  .curriculum_accordion_wrap {
    .curriculum_accordion_ul {
      &:not(:first-of-type) {
        li:first-of-type {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
}

.benefit_slider {
  :global {
    .slick-slide {
      width: 340px;
    }

    .slick-dots {
      position: relative;
      padding-top: 35px;
      bottom: 0;
      z-index: 1;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 6px;
          height: 6px;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 1;
            content: '';
            width: 6px;
            height: 6px;
            background-color: #737373;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .benefit_slider {
    :global {
      .slick-slide {
        width: 296px;
      }
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}

.faq_item_title {
  position: relative;
  padding-left: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Q.';
    margin-right: 4px;
    color: #ff7710;
  }
}

@media (max-width: 1023px) {
  .faq_item_title {
    padding-left: 18px;
  }
}
