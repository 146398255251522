.section1_kv {
  padding-bottom: 56.25%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-growth-1st/section1_kv_before_load_pc.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
  }
}
@media (max-width: 1023px) {
  .section1_kv {
    padding-bottom: 138.89%;

    &::before {
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-growth-1st/section1_kv_before_load_m.webp');
    }
  }
}

.kv_icon_arrow {
  > img {
    animation: 2s arrow infinite linear;

    @keyframes arrow {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(30px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .kv_icon_arrow {
    bottom: -20px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .kv_icon_arrow {
    width: 40px !important;
    height: 40px !important;
    margin-left: -20px !important;
  }
}

.section3_li_deco {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #262626;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .section3_li_deco {
    padding-left: 11px;

    &::before {
      top: 16px;
    }
  }
}

.section4_slider {
  :global {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        width: 43px;
        height: 43px;
        background-color: rgba(255, 255, 255, 0.1);
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-growth-1st/section4_slider_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px 20px;
        border-radius: 4px;
        margin-top: 35px;
        z-index: 1;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: 40px;
        transform: translate(0, -50%) rotate(180deg);
      }
      &.slick-next {
        right: 40px;
      }
    }

    .slick-dots {
      top: 0;
      bottom: initial;
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow: hidden;

      li {
        position: relative;
        width: 25%;
        height: auto;
        margin: 0;

        button {
          position: relative;
          width: 100%;
          height: 34px;
          padding: 0;
          z-index: 3;

          &::before {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100%;
            color: #a3a3a3;
            font-size: 14px;
            font-weight: 700;
            font-family: 'Pretendard';
            white-space: nowrap;
            opacity: 1;
            z-index: 3;
          }

          &::after {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0);
            content: '';
            width: 100%;
            height: 6px;
            background-color: #a3a3a3;
            z-index: 3;
          }
        }

        &.slick-active {
          button {
            &::before {
              color: #ff7710;
            }
            &::after {
              background-color: #ff7710;
            }
          }
        }

        &:nth-of-type(1) {
          button {
            &::before {
              content: 'QUESTION. 1';
            }
          }
        }

        &:nth-of-type(2) {
          button {
            &::before {
              content: 'QUESTION. 2';
            }
          }
        }

        &:nth-of-type(3) {
          button {
            &::before {
              content: 'QUESTION. 3';
            }
          }
        }

        &:nth-of-type(4) {
          button {
            &::before {
              content: 'MISSION';
            }
          }
        }
      }

      &:has(li:nth-of-type(2).slick-active) {
        li:nth-of-type(1) {
          button {
            &::before {
              opacity: 1;
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
            }
          }
        }
      }

      &:has(li:nth-of-type(3).slick-active) {
        li:nth-of-type(1),
        li:nth-of-type(2) {
          button {
            &::before {
              opacity: 1;
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
            }
          }
        }
      }

      &:has(li:nth-of-type(4).slick-active) {
        li:nth-of-type(1),
        li:nth-of-type(2),
        li:nth-of-type(3) {
          button {
            &::before {
              opacity: 1;
              color: #ff7710;
            }

            &::after {
              background-color: #ff7710;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .section4_slider {
    :global {
      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          width: 20px;
          height: 20px;
          background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-growth-1st/section4_slider_arrow_m.png');
          margin-top: 20px;
        }
        &.slick-prev {
          left: 16px;
        }
        &.slick-next {
          right: 16px;
        }
      }

      .slick-dots {
        li {
          button {
            height: 26px;

            &::before {
              font-size: 12px;
              font-family: 'Pretendard';
            }

            &::after {
              height: 4px;
            }
          }

          &:nth-of-type(1) {
            button {
              &::before {
                content: 'Q. 1';
              }
            }
          }

          &:nth-of-type(2) {
            button {
              &::before {
                content: 'Q. 2';
              }
            }
          }

          &:nth-of-type(3) {
            button {
              &::before {
                content: 'Q. 3';
              }
            }
          }

          &:nth-of-type(4) {
            button {
              &::before {
                content: 'MISSION';
              }
            }
          }
        }
      }
    }
  }
}

.section4_bg {
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-growth-1st/section4_bg_pc.png');
}

@media (max-width: 1023px) {
  .section4_bg {
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-growth-1st/section4_bg_m.png');
  }
}

.section5_bg {
  background: radial-gradient(91.63% 50% at 50% 50%, #261203 0%, #262626 100%);
}

.section6_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}
@media (max-width: 1023px) {
  .section6_dot {
    padding-left: 11px;

    &::before {
      top: 8px;
    }
  }
}

.curriculum_accordion_wrap {
  li {
    &:first-of-type {
      .curriculum_accordion_dot_box {
        align-items: flex-start;

        span {
          &::before {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      .curriculum_accordion_dot_box {
        span {
          &::after {
            display: none;
          }
        }
      }
    }

    .curriculum_accordion_dot_box {
      span {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          content: '';
          width: 2px;
          height: 999vh;
          background-color: #a3a3a3;
          z-index: 0;
        }

        &::before {
          top: calc(-999vh - 2px);
        }
        &::after {
          bottom: calc(-999vh - 2px);
        }
      }
    }
  }

  .curriculum_accordion_dot_ul {
    position: relative;

    &::before {
      position: absolute;
      top: 40px;
      left: 5.4px;
      content: '';
      width: 2px;
      height: calc(100% - 87px);
      background-color: #a3a3a3;
      z-index: 0;
    }
  }
}

.step_text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 16px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #262626;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .step_text_dot {
    padding-left: 11px;

    &::before {
      top: 15px;
    }
  }
}

.section8_bg {
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-growth-1st/section8_pc.png');
}

@media (max-width: 1023px) {
  .section8_bg {
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-growth-1st/section8_m.png');
  }
}

.teacher_desc_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #737373;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .teacher_desc_dot {
    padding-left: 11px;

    &::before {
      top: 8px;
    }
  }
}

.section9_bg {
  background: radial-gradient(91.63% 50% at 50% 50%, #261203 0%, #262626 100%);
}

.section9_ul {
  li {
    &:nth-of-type(1) {
      width: calc(31.91% - 24px);
    }
    &:nth-of-type(2) {
      width: calc(31.91% - 24px);
    }
    &:nth-of-type(3) {
      width: calc(36.17% - 24px);
    }
    &:nth-of-type(4) {
      width: 400px;
    }
    &:nth-of-type(5) {
      width: 420px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .section9_ul {
    gap: 12px;
    li {
      &:nth-of-type(1) {
        width: calc(31.91% - 8px);
      }
      &:nth-of-type(2) {
        width: calc(31.91% - 8px);
      }
      &:nth-of-type(3) {
        width: calc(36.17% - 8px);
      }
      &:nth-of-type(4) {
        width: 360px;
      }
      &:nth-of-type(5) {
        width: 397px;
      }

      > p {
        font-size: 17px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .section9_ul {
    li {
      &:nth-of-type(1) {
        width: 100%;
        min-width: initial;
      }
      &:nth-of-type(2) {
        width: 100%;
        min-width: initial;
      }
      &:nth-of-type(3) {
        width: 100%;
        min-width: initial;
      }
      &:nth-of-type(4) {
        width: 100%;
      }
      &:nth-of-type(5) {
        width: 100%;
      }
    }
  }
}

.section10_slider_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 20px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .section10_slider {
    :global {
      .slick-slide {
        width: 258px;
      }

      .slick-dots {
        position: initial;
        margin-top: 10px;

        li {
          width: 6px;
          height: 6px;
          margin: 0 4px;

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              top: 0;
              left: 0;
              opacity: 1;
              content: '';
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.5);
              border-radius: 50%;
            }
          }
          &.slick-active {
            button {
              &::before {
                background-color: #fff;
              }
            }
          }
        }
      }
    }
    .section10_slider_dot {
      position: relative;
      padding-left: 11px;

      &::before {
        position: absolute;
        top: 20px;
        left: 0;
        content: '';
        width: 3px;
        height: 3px;
        background-color: #d4d4d4;
        border-radius: 50%;
      }
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}

.faq_item_title {
  position: relative;
  padding-left: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Q.';
    margin-right: 4px;
    color: #ff7710;
  }
}

@media (max-width: 1023px) {
  .faq_item_title {
    padding-left: 18px;
  }
}
