.container {
  margin: 0px;
  table td {
    padding: 16px;
    border: 1px solid #d4d4d4;
  }
  pre {
    width: 100%;
    white-space: pre;
    overflow: auto;
    background-color: #f5f2f0;
  }
  code {
    background-color: #f5f2f0;
    padding: 16px;
    display: inline-block;
    width: 100%;
    overflow: auto;
  }
  ul,
  ol {
    padding: 0 16px;
  }
  a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #335dff;
  }

  details {
    position: relative;
    cursor: pointer;
    width: 100%;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: #d4d4d4;
    background-color: #fff;
    padding: 20px 16px;
  }

  summary {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    padding-right: 30px;
    word-break: break-word;
  }

  details > div,
  details > p,
  details > ol,
  details > ul {
    margin-top: 0.75rem;
    border-radius: 0.5rem;
    padding: 1rem;
    color: #737373;
    background-color: #f5f5f5;
  }

  details > ol,
  details > ul {
    list-style-position: inside;
  }
}
@media (max-width: 1023px) {
  .container {
    margin: 0 16px;
  }
}

.apply_btn_icon_arrow {
  animation: 1s arrow infinite linear;

  @keyframes arrow {
    0% {
      transform: translateX(-3px);
    }
    50% {
      transform: translateX(7px);
    }
    100% {
      transform: translateX(-3px);
    }
  }
}
