.kv_video {
  padding-bottom: 28.125%;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;

    &::-webkit-media-controls {
      display: none !important;
    }
  }
}

@media (max-width: 1023px) {
  .kv_video {
    padding-bottom: 117.78%;
  }
}

.section2_dot {
  position: relative;
  padding-left: 18px;

  &::before {
    position: absolute;
    top: 9px;
    left: 6px;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #fff;
    border-radius: 50%;
  }
}

.tab_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

.section3_bg {
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 60px;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section3_bg_pc.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain;
  }
}

@media (max-width: 1023px) {
  .section3_bg {
    &::before {
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section3_bg_m.png');
    }
  }
}

.section6_bg {
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 232px;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section6_bg_pc.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain;
  }
}

@media (max-width: 1023px) {
  .section6_bg {
    &::before {
      height: 120px;
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section6_bg_m.png');
    }
  }
}

.step_text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .step_text_dot {
    padding-left: 10px;

    &::before {
      top: 18px;
      width: 2px;
      height: 2px;
    }
  }
}

.section8_bg {
  background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section8_bg_pc.png');
}

@media (max-width: 1023px) {
  .section8_bg {
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section8_bg_m.png');
  }
}

.teacher_text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #d4d4d4;
  }
}

@media (max-width: 1023px) {
  .teacher_text_dot {
    padding-left: 11px;

    &::before {
      top: 16px;
    }
  }
}

.section10_bg {
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 107.31px;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section10_bg_pc.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain;
  }
}

@media (max-width: 1023px) {
  .section10_bg {
    &::before {
      height: 60px;
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section10_bg_m.png');
    }
  }
}

.accordion_text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 8.5px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .accordion_text_dot {
    padding-left: 10px;

    &::before {
      top: 10px;
      width: 2px;
      height: 2px;
    }
  }
}

.curriculum_accordion_wrap {
  li {
    &:first-of-type {
      .curriculum_accordion_dot_box {
        align-items: flex-start;

        span {
          &::before {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      .curriculum_accordion_dot_box {
        span {
          &::after {
            display: none;
          }
        }
      }
    }

    .curriculum_accordion_dot_box {
      span {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          content: '';
          width: 2px;
          height: 999vh;
          background-color: #a3a3a3;
          z-index: 0;
        }

        &::before {
          top: calc(-999vh - 2px);
        }
        &::after {
          bottom: calc(-999vh - 2px);
        }
      }
    }
  }

  .curriculum_accordion_dot_ul {
    position: relative;

    &::before {
      position: absolute;
      top: 40px;
      left: 5.4px;
      content: '';
      width: 2px;
      height: calc(100% - 87px);
      background-color: #a3a3a3;
      z-index: 0;
    }
  }
}

@media (max-width: 1023px) {
  .curriculum_accordion_wrap {
    .curriculum_accordion_ul {
      &:not(:first-of-type) {
        li:first-of-type {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
}

.section5_slider,
.section11_slider {
  :global {
    .slick-list {
      padding-left: 24.8%;
      padding-bottom: 60px;
    }

    .slick-slide {
      width: 604px;
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-color: #171717;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section4_slider_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        z-index: 2;

        &:hover {
          background-color: #000;
        }

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }
  }
}

.section11_slider {
  :global {
    .slick-list {
      padding-bottom: 104px;
    }

    .slick-slide {
      width: 419px;
    }
  }
}

@media (max-width: 1920px) {
  .section5_slider,
  .section11_slider {
    :global {
      .slick-list {
        padding-left: 16.7%;
      }
    }
  }
}

@media (max-width: 1728px) {
  .section5_slider,
  .section11_slider {
    :global {
      .slick-list {
        padding-left: 12.8%;
      }
    }
  }
}

@media (max-width: 1536px) {
  .section5_slider,
  .section11_slider {
    :global {
      .slick-list {
        padding-left: 8.4%;
      }
    }
  }
}

@media (max-width: 1512px) {
  .section5_slider,
  .section11_slider {
    :global {
      .slick-list {
        padding-left: 8%;
      }
    }
  }
}

@media (max-width: 1440px) {
  .section5_slider,
  .section11_slider {
    :global {
      .slick-list {
        padding-left: 5.5%;
      }
    }
  }

  .section11_slider {
    :global {
      .slick-slide {
        width: 312px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .section5_slider,
  .section11_slider {
    :global {
      .slick-list {
        position: relative;
        padding-bottom: 0;
      }

      .slick-dots {
        position: initial;
        margin-top: 26px;

        li {
          width: 6px;
          height: 6px;
          margin: 0 4px;

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              top: 0;
              left: 0;
              opacity: 1;
              content: '';
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.5);
              border-radius: 50%;
            }
          }
          &.slick-active {
            button {
              &::before {
                background-color: #fff;
              }
            }
          }
        }
      }

      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          position: absolute;
          top: calc(50% - 27px);
          bottom: initial;
          width: 30px;
          height: 30px;
          background-color: transparent;
          background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section4_slider_arrow_m.png');
          background-size: cover;
          border-radius: 50%;

          &:hover {
            background-color: initial;
          }
        }
        &.slick-prev {
          left: 9px;
          transform: translate(0, -50%) rotate(180deg);
        }
        &.slick-next {
          right: 9px;
          transform: translate(0, -50%);
        }
      }
    }
  }

  .section11_slider {
    :global {
      .slick-list {
        padding-left: initial;
      }

      .slick-slide {
        width: 312px;
      }

      .slick-dots {
        margin-top: 16px;
      }
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}

.faq_item_title {
  position: relative;
  padding-left: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Q.';
    margin-right: 4px;
    color: #ff7710;
  }
}

@media (max-width: 1023px) {
  .faq_item_title {
    padding-left: 18px;
  }
}
