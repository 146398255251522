@import '@/styles/mixin.scss';

.MinWidth {
  min-width: 320px;
}

.intro_review_slide {
  :global {
    .slick-list {
      height: 206px;
      overflow: hidden;
    }

    .slick-slide {
      width: 100% !important;

      &:not(.slick-active) {
        opacity: 0;
        transition: opacity 0.2s ease-out;
      }

      &.slick-active {
        opacity: 1 !important;
        transition: opacity 0.2s ease-in;
      }
    }
  }
}

@media (max-width: 1023px) {
  .intro_review_slide {
    :global {
      .slick-list {
        height: 186px;
        overflow: hidden;
      }

      .slick-slide {
        height: 186px !important;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}

.portfolio_slide {
  margin: 0 auto;

  :global {
    .slick-slide {
      transition: all 0.3s;

      &:not(.slick-center) {
        filter: blur(9.960975646972656px);
        opacity: 0.3;
        transform: scale(0.8);
      }
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        width: inherit;
        height: inherit;
        margin-top: -30px;
        border-radius: 50%;
        z-index: 3;

        &.slick-disabled {
          display: none !important;
        }

        &::before {
          display: none !important;
        }
      }

      &.slick-prev {
        left: calc(34% + 29px);
      }

      &.slick-next {
        right: calc(34% + 29px);
      }
    }
  }
}

@media (max-width: 1439px) {
  .portfolio_slide {
    :global {
      .slick-arrow {
        &.slick-prev {
          left: calc(25% + 16px + 8px);
        }

        &.slick-next {
          right: calc(25% + 16px + 8px);
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .portfolio_slide {
    :global {
      .slick-slide {
        padding: 0 8px;
      }

      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          margin-top: -26px;
        }

        &.slick-prev {
          left: calc(20% + 16px + 8px);
        }

        &.slick-next {
          right: calc(20% + 16px + 8px);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .portfolio_slide {
    :global {
      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          margin-top: -18px;
        }

        &.slick-prev {
          left: 32px;
        }

        &.slick-next {
          right: 32px;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .video_slide {
    :global {
      .slick-slide {
        padding: 0 8px;
      }

      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          width: inherit;
          height: inherit;
          margin-top: -49.25px;
          border-radius: 50%;
          z-index: 3;

          &.slick-disabled {
            display: none !important;
          }

          &::before {
            display: none !important;
          }
        }

        &.slick-prev {
          left: calc(20% + 15px + 8px);
        }

        &.slick-next {
          right: calc(20% + 15px + 8px);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .video_slide {
    :global {
      .slick-slide {
        padding: 0 8px;
      }

      .slick-arrow {
        &.slick-prev {
          left: 32px;
        }

        &.slick-next {
          right: 32px;
        }
      }
    }
  }
}

.section3_li {
  &_1st {
    > div:last-of-type {
      .pulse_live {
        animation: customPulsing 2s infinite;

        @keyframes customPulsing {
          0% {
            box-shadow: 0 0 0 0 rgba(255, 119, 16, 0.4);
          }
          70% {
            box-shadow: 0 0 0 20px rgba(255, 119, 16, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(255, 119, 16, 0);
          }
        }
      }
    }
  }

  &_2rd {
    > div:last-of-type {
      & > img {
        border-radius: 20px;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.07);

        &:nth-of-type(1) {
          top: 10px;
          left: 82px;
        }
        &:nth-of-type(2) {
          top: 10px;
          left: 183px;
        }
        &:nth-of-type(3) {
          top: 130px;
          left: 120px;
        }
        &:nth-of-type(4) {
          top: 130px;
          left: 221px;
        }
        &:nth-of-type(5) {
          top: 130px;
          left: 323px;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1235px) {
  .section3_ul_wrap {
    gap: 16.27px;

    li {
      > div:first-of-type {
        margin-left: 32px;
        margin-right: 32px;

        > p:nth-of-type(1) {
          font-size: 13px;
        }

        > p:nth-of-type(2) {
          font-size: 22px;
        }
        > p:nth-of-type(3) {
          font-size: 13px;
        }
      }
    }

    .section3_li {
      &_1st {
        > div:last-of-type {
          .pulse_live {
            left: 15%;
          }

          & > img {
            left: initial;
            right: 0;
            width: 25vw;
            min-width: 264px;
            height: 18.37vw;
            min-height: 194px;
          }
        }
      }

      &_2rd {
        > div:last-of-type {
          & > img {
            width: 6.2vw;
            min-width: 63.79px;
            height: 7.54vw;
            min-height: 77.58px;
            border-radius: 13.793px;

            &:nth-of-type(1) {
              top: calc(50% - 7.54vw - 8.62px);
              left: calc(15%);
            }
            &:nth-of-type(2) {
              top: calc(50% - 7.54vw - 8.62px);
              left: calc(15% + 6.2vw + 17.24px);
            }
            &:nth-of-type(3) {
              top: initial;
              bottom: calc(50% - 7.54vw - 8.62px);
              left: calc(15% + 3.1vw);
            }
            &:nth-of-type(4) {
              top: initial;
              bottom: calc(50% - 7.54vw - 8.62px);
              left: calc(15% + 3.1vw + 6.2vw + 17.24px);
            }
            &:nth-of-type(5) {
              top: initial;
              bottom: calc(50% - 7.54vw - 8.62px);
              left: calc(15% + 3.1vw + 6.2vw + 6.2vw + 17.24px + 17.24px);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .section3_li {
    &_2rd {
      > div:last-of-type {
        & > img {
          border-radius: 16px;
          box-shadow: 0px 3.567px 26.751px 0px rgba(48, 48, 48, 0.1);

          &:nth-of-type(1) {
            top: 15px;
            left: 58px;
          }
          &:nth-of-type(2) {
            top: 15px;
            left: 152px;
          }
          &:nth-of-type(3) {
            top: 125px;
            left: 90px;
          }
          &:nth-of-type(4) {
            top: 125px;
            left: 184px;
          }
          &:nth-of-type(5) {
            top: 125px;
            left: 278px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .section3_li {
    &_1st {
      > div:last-of-type {
        .pulse_live {
          top: 26%;
          left: 10%;
          width: 25vw;
          min-width: 92.37px;
          height: 10.58vw;
          min-height: 39.08px;
          font-size: clamp(20px, 4.5vw, 35px);
        }
        > img:last-of-type {
          top: 0;
          left: initial;
          right: 0;
          width: 80vw;
          min-width: 288px;
          height: auto;
          object-fit: cover;
        }
      }
    }

    &_2rd {
      > div:last-of-type {
        & > img {
          width: 20vw;
          min-width: 74px;
          height: 24.32vw;
          min-height: 90px;
          border-radius: 16px;
          box-shadow: 0px 3.567px 26.751px 0px rgba(48, 48, 48, 0.1);

          &:nth-of-type(1) {
            top: calc(50% - 24.32vw - 10px);
            left: calc(15%);
          }
          &:nth-of-type(2) {
            top: calc(50% - 24.32vw - 10px);
            left: calc(15% + 20vw + 20px);
          }
          &:nth-of-type(3) {
            top: initial;
            bottom: calc(50% - 24.32vw - 10px);
            left: calc(5% + 20vw);
          }
          &:nth-of-type(4) {
            top: initial;
            bottom: calc(50% - 24.32vw - 10px);
            left: calc(5% + 40vw + 20px);
          }
          &:nth-of-type(5) {
            top: initial;
            bottom: calc(50% - 24.32vw - 10px);
            left: calc(5% + 60vw + 40px);
          }
        }
      }
    }
  }
}

.custom_flow_slider {
  $animationSpeed: 10s; // 슬라이드가 부드럽게 움직이도록 시간 설정

  @keyframes scrollFirst {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-64px * 10 - 32px * 10)); /* 10개의 슬라이드 + 10개의 간격 */
    }
  }

  position: relative;
  width: 100%;
  height: 64px;
  overflow: hidden;

  &_track {
    display: flex;
    gap: 32px;
    width: calc(64px * 20 + 32px * 20); /* 10개의 슬라이드 * 2 반복 + 간격 포함 */
    animation: scrollFirst $animationSpeed linear infinite;
  }

  &_item {
    width: 64px;
    height: 64px;
  }
}

@media (max-width: 1023px) {
  .custom_flow_slider {
    $animationSpeed: 10s;

    @keyframes scrollFirst {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-60px * 10 - 24px * 10));
      }
    }

    height: 60px;

    &_track {
      gap: 24px;
      width: calc(60px * 20 + 24px * 20);
      animation: scrollFirst $animationSpeed linear infinite;
    }

    &_item {
      width: 60px;
      height: 60px;
    }
  }
}

@media (max-width: 767px) {
  .custom_flow_slider {
    $animationSpeed: 10s;

    @keyframes scrollFirst {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-16.67vw * 10 - 6.67vw * 10));
      }
    }

    height: 16.67vw;

    &_track {
      gap: 6.67vw;
      width: calc(16.67vw * 20 + 6.67vw * 20);
      animation: scrollFirst $animationSpeed linear infinite;
    }

    &_item {
      width: 16.67vw;
      min-width: 60px;
      height: 16.67vw;
      min-height: 60px;
    }
  }
}

.sticky_bnr {
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
  transition: opacity 0.5s ease-in-out;
  z-index: 11;
}

.apply_list_li {
  &:nth-of-type(1) {
    .desc {
      color: #e0fffc;
    }
  }
  &:nth-of-type(2) {
    > p:first-of-type {
      &::after {
        content: ' : 안드로이드';
      }
    }
    .desc {
      color: #d3ffec;
    }
  }
  &:nth-of-type(3) {
    .desc {
      color: #d9eaff;
    }
  }
  &:nth-of-type(4) {
    .desc {
      color: #c7c7c7;
    }
  }

  .custom_font {
    padding-top: 1vw;
    padding-left: 1vw;

    p {
      &:nth-of-type(1) {
        font-size: clamp(14px, 3vw, 23px);
      }
      &:nth-of-type(2) {
        font-size: clamp(18px, 5vw, 38px);
      }
    }
  }
}

@media (max-width: 768px) {
  .apply_list_li {
    &:nth-of-type(2) {
      > p:first-of-type {
        &::after {
          content: '';
        }
      }
    }
  }
}

.section3_img_custom {
  margin: 0 -14px;
}
@media (max-width: 1024px) {
  .section3_img_custom {
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .section3_img_custom {
    margin: 0 -14px;
  }
}

.custom_box_shadow {
  box-shadow: 2px 4px 8px 0px rgba(214, 120, 3, 0.2);
}

.company_slide {
  :global {
    .slick-slide {
      width: 208px !important;
      height: 80px;
      padding: 0 4px;
    }
  }
}

@media (max-width: 1023px) {
  .company_slide {
    :global {
      .slick-slide {
        width: 158px !important;
        height: 60px;
      }
    }
  }
}

.company_logo_flow_slider {
  $firstAnimationSpeed: 15s; // 첫 슬라이더 기준 속도
  $secondAnimationSpeed: calc(15s * 1.143); // 두 번째 슬라이더는 첫 번째 슬라이더보다 1.143배 빠름

  // 첫 번째 슬라이더 애니메이션 (왼쪽으로 흐름)
  @keyframes scrollFirst {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-200px * 7 - 8px * 7)); /* 7개의 슬라이드 */
    }
  }

  position: relative;
  width: 100%;
  height: 80px;
  overflow: hidden;

  &_track {
    display: flex;
    gap: 8px;
    width: calc(200px * 21 + 8px * 21); /* 7개의 슬라이드 * 3 반복 */
    animation: scrollFirst $firstAnimationSpeed linear infinite;
  }

  &_item {
    width: 200px;
    height: 80px;
  }

  &_second {
    margin-top: 18px;

    // 두 번째 슬라이더 애니메이션 (오른쪽으로 흐름)
    @keyframes scrollSecond {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-200px * 8 - 8px * 8));
      }
    }

    .company_logo_flow_slider_track {
      display: flex;
      gap: 8px;
      width: calc(200px * 24 + 8px * 24); /* 8개의 슬라이드 * 3 반복 */
      animation: scrollSecond $secondAnimationSpeed linear infinite reverse; /* 애니메이션을 reverse로 설정해 오른쪽으로 이동 */
    }
  }
}

@media (max-width: 1023px) {
  .company_logo_flow_slider {
    @keyframes scrollFirst {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-150px * 7 - 8px * 7)); /* 7개의 슬라이드 */
      }
    }

    width: 100%;
    height: 60px;

    &_track {
      width: calc(150px * 21 + 8px * 21); /* 7개의 슬라이드 * 3 반복 */
    }

    &_item {
      width: 150px;
      height: 60px;
    }

    &_second {
      margin-top: 12px;

      @keyframes scrollSecond {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-150px * 8 - 8px * 8));
        }
      }

      .company_logo_flow_slider_track {
        width: calc(150px * 24 + 8px * 24);
      }
    }
  }
}

.deco_review_slider {
  :global {
    .slick-slide {
      width: calc(336px + 32px) !important;
    }
  }

  .for_ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 4; /* 4줄까지만 보이도록 설정 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 1023px) {
  .deco_review_slider {
    :global {
      width: 100% !important;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1235px) {
  .apply_step_wrap {
    flex-wrap: wrap;
    max-width: 728px;
    gap: 32px 0;

    li {
      &:nth-of-type(6) {
        display: none;
      }
    }
  }
}

.customCloseButton {
  &:hover {
    background-color: transparent !important;
  }
}
