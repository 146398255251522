.nebeca_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #737373;
    border-radius: 50%;
  }
}
@media (max-width: 1023px) {
  .nebeca_dot {
    padding-left: 6px;

    &::before {
      top: 7px;
      width: 2px;
      height: 2px;
    }
  }
}

.custom_flow_slider {
  @keyframes scrollFirst {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-325px * 4 - 16px * 4)); // 4개의 슬라이드 + 4개의 간격
    }
  }

  position: relative;
  width: 100%;
  height: 174px;
  overflow: hidden;

  &_track {
    display: flex;
    gap: 16px;
    width: calc(325px * 12 + 16px * 12); // 4개의 슬라이드 * 3 반복 + 간격 포함
    animation: scrollFirst 13s linear infinite;
  }

  &_item {
    width: 325px;
    height: 174px;
    flex-shrink: 0;
  }

  &_second {
    width: calc(100% + 162.5px + 8px);
    margin-top: 16px;
    margin-left: calc(-162.5px - 8px);

    @keyframes scrollSecond {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-325px * 4 - 16px * 4));
      }
    }

    .custom_flow_slider_track {
      width: calc(325px * 12 + 16px * 12); // 4개의 슬라이드 * 3 반복 + 간격 포함
      animation: scrollSecond 13s linear infinite;
    }
  }
}

@media (max-width: 1023px) {
  .custom_flow_slider {
    @keyframes scrollFirst {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-243px * 4 - 16px * 4)); // 4개의 슬라이드 + 4개의 간격
      }
    }
    height: 159px;

    &_track {
      gap: 12px;
      width: calc(243px * 12 + 12px * 12); // 4개의 슬라이드 * 3 반복 + 간격 포함
    }

    &_item {
      width: 243px;
      height: 159px;
    }

    &_second {
      width: calc(100% + 121.5px + 6px);
      margin-top: 12px;
      margin-left: calc(-121.5px - 6px);

      @keyframes scrollSecond {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-243px * 4 - 12px * 4));
        }
      }

      .custom_flow_slider_track {
        width: calc(243px * 12 + 12px * 12); // 4개의 슬라이드 * 3 반복 + 간격 포함
      }
    }
  }
}

.custom_gradient {
  background: radial-gradient(91.63% 50% at 50% 50%, #261203 0%, #262626 100%);
}

.step_text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .step_text_dot {
    padding-left: 11px;

    &::before {
      top: 16px;
    }
  }
}

.dataton_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #737373;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .dataton_dot {
    padding-left: 15px;

    &::before {
      top: 16px;
    }
  }
}

.section10_dot {
  &::before {
    margin-right: 5px;
    content: '-';
    color: #262626;
  }
}

@media (max-width: 1023px) {
  .section10_dot {
    position: relative;
    padding-left: 15px;

    &::before {
      position: absolute;
      top: 16px;
      left: 0;
      content: '';
      width: 3px;
      height: 3px;
      background-color: #262626;
      border-radius: 50%;
    }
  }
}

.section11_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

.section12_slider {
  :global {
    .slick-list {
      padding-left: 24.8%;
      padding-bottom: 84px;
    }

    .slick-slide {
      width: 419px;
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-color: #262626;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section4_slider_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        z-index: 2;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }
  }
}

@media (max-width: 1920px) {
  .section12_slider {
    :global {
      .slick-list {
        padding-left: 16.7%;
      }
    }
  }
}

@media (max-width: 1728px) {
  .section12_slider {
    :global {
      .slick-list {
        padding-left: 12.8%;
      }
    }
  }
}

@media (max-width: 1536px) {
  .section12_slider {
    :global {
      .slick-list {
        padding-left: 8.4%;
      }
    }
  }
}

@media (max-width: 1512px) {
  .section12_slider {
    :global {
      .slick-list {
        padding-left: 8%;
      }
    }
  }
}

@media (max-width: 1440px) {
  .section12_slider {
    :global {
      .slick-list {
        padding-left: 5.5%;
      }
    }
  }
}

@media (max-width: 1299px) {
  .section12_slider {
    :global {
      .slick-slide {
        width: 42vw;
      }
    }
  }
}

@media (max-width: 1023px) {
  .section12_slider {
    :global {
      .slick-list {
        padding-left: initial;
        padding-bottom: 0;
      }

      .slick-slide {
        width: 258px;
      }

      .slick-dots {
        position: initial;
        margin-top: 30px;

        li {
          width: 6px;
          height: 6px;
          margin: 0 4px;

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              top: 0;
              left: 0;
              opacity: 1;
              content: '';
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.5);
              border-radius: 50%;
            }
          }
          &.slick-active {
            button {
              &::before {
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.section13_box {
  position: relative;

  &::before {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    content: '';
    width: 10px;
    height: 274px;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-dataanalysis-4th/section13_box_deco_pc.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
  }
}

@media (max-width: 1279px) {
  .section13_box {
    &::before {
      height: 274px;
    }
  }
}

@media (max-width: 1260px) {
  .section13_box_dot1,
  .section13_box_dot2 {
    br {
      display: none;
    }
  }
}

@media (max-width: 1100px) {
  .section13_box {
    &::before {
      height: 285px;
    }
  }
}

@media (max-width: 1023px) {
  .section13_box {
    &::before {
      top: 94px;
      height: 480px;
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-dataanalysis-4th/section13_box_deco_m.png');
    }
  }
}

@media (max-width: 684px) {
  .section13_box {
    &::before {
      height: 500px;
    }
  }
}

@media (max-width: 518px) {
  .section13_box {
    &::before {
      height: 520px;
    }
  }
}

@media (max-width: 480px) {
  .section13_box {
    &::before {
      height: 545px;
    }
  }
}

@media (max-width: 458px) {
  .section13_box {
    &::before {
      height: 570px;
    }
  }
}

@media (max-width: 389px) {
  .section13_box {
    &::before {
      height: 595px;
    }
  }
}

@media (max-width: 383px) {
  .section13_box {
    &::before {
      height: 635px;
    }
  }
}

.section13_box_dot1,
.section13_box_dot2 {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #262626;
    border-radius: 50%;
  }
}

.section13_box_dot2 {
  &::before {
    background-color: #d4d4d4;
  }
}

.section14_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .section14_dot {
    padding-left: 15px;

    &::before {
      top: 15.5px;
    }
  }
}

.teacher_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }

  &_white {
    &::before {
      background-color: #fff;
    }
  }
}

@media (max-width: 1023px) {
  .teacher_dot {
    padding-left: 15px;

    &::before {
      top: 15.5px;
    }
  }
}

.condition_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 15px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #262626;
    border-radius: 50%;
  }
}

@media (max-width: 1023px) {
  .condition_dot {
    padding-left: 11px;

    &::before {
      top: 16px;
    }
  }
}

.curriculum_accordion_wrap {
  li {
    &:first-of-type {
      .curriculum_accordion_dot_box {
        align-items: flex-start;

        span {
          &::before {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      .curriculum_accordion_dot_box {
        span {
          &::after {
            display: none;
          }
        }
      }
    }

    .curriculum_accordion_dot_box {
      span {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          content: '';
          width: 2px;
          height: 999vh;
          background-color: #a3a3a3;
          z-index: 0;
        }

        &::before {
          top: calc(-999vh - 2px);
        }
        &::after {
          bottom: calc(-999vh - 2px);
        }
      }
    }
  }

  .curriculum_accordion_dot_ul {
    position: relative;

    &::before {
      position: absolute;
      top: 40px;
      left: 5.4px;
      content: '';
      width: 2px;
      height: calc(100% - 87px);
      background-color: #a3a3a3;
      z-index: 0;
    }
  }
}

@media (max-width: 1023px) {
  .curriculum_accordion_wrap {
    li {
      &:nth-of-type(5) {
        ul {
          li.step_text_dot {
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
              color: white;
              &::before {
                background-color: #fff;
              }
            }
            &:nth-of-type(4) {
              padding-bottom: 32px;
            }
            &:nth-of-type(5) {
              padding-top: 32px;
              &::before {
                top: 40px;
              }
            }
          }
        }
      }
      &:nth-of-type(6) {
        ul {
          li.step_text_dot {
            &:nth-of-type(2),
            &:nth-of-type(3) {
              color: white;
              &::before {
                background-color: #fff;
              }
            }
            &:nth-of-type(3) {
              padding-bottom: 32px;
            }
            &:nth-of-type(4) {
              padding-top: 32px;
              &::before {
                top: 40px;
              }
            }
          }
        }
      }
      &:nth-of-type(7) {
        ul {
          li.step_text_dot {
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
              color: white;
              &::before {
                background-color: #fff;
              }
            }
            &:nth-of-type(4) {
              padding-bottom: 32px;
            }
            &:nth-of-type(5) {
              padding-top: 32px;
              &::before {
                top: 40px;
              }
            }
          }
        }
      }
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}

.faq_item_title {
  position: relative;
  padding-left: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Q.';
    margin-right: 4px;
    color: #ff7710;
  }
}

@media (max-width: 1023px) {
  .faq_item_title {
    padding-left: 18px;
  }
}
